/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { 
  RiArrowRightSLine, 
  RiCheckboxCircleLine, 
  RiToolsLine, 
  RiTeamLine, 
  RiComputerLine,
  RiGlobalLine,
  RiShieldCheckLine,
  RiTimeLine,
  RiAlertLine,
  RiFileTextLine
} from "react-icons/ri"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const RemoteWorkPage = () => {
  const remoteWorkAreas = [
    {
      id: 'technology',
      title: 'Technology & Infrastructure',
      items: [
        {
          text: 'Video Conferencing Solutions',
          url: 'https://zoom.us',
          description: 'Zoom - Industry leading video conferencing platform'
        },
        {
          text: 'Project Management Tools',
          url: 'https://asana.com',
          description: 'Asana - Collaborative task and project management'
        },
        {
          text: 'Communication Platforms',
          url: 'https://slack.com',
          description: 'Slack - Real-time team messaging and collaboration'
        },
        {
          text: 'Cloud Storage Solutions',
          url: 'https://workspace.google.com',
          description: 'Google Workspace - Secure document sharing and collaboration'
        }
      ]
    },
    {
      id: 'policies',
      title: 'Remote Work Policies',
      items: [
        {
          text: 'Work Hours & Scheduling',
          url: '/policies',
          description: 'Access our HR policy templates and guidelines for work scheduling'
        },
        {
          text: 'Communication Standards',
          url: '/guides',
          description: 'Browse our comprehensive HR guides for team communication'
        },
        {
          text: 'Performance Management',
          url: '/guides/performance-reviews',
          description: 'Complete guide to managing and conducting performance reviews'
        },
        {
          text: 'Security Guidelines',
          url: '/policies',
          description: 'Access our policy templates for workplace security and compliance'
        }
      ]
    }
  ]

  const commonChallenges = [
    {
      id: 'communication',
      title: 'Communication Barriers',
      description: 'Overcoming challenges in virtual team communication and collaboration.',
      solutions: [
        'Implement structured communication channels',
        'Schedule regular team check-ins',
        'Use asynchronous communication tools',
        'Document important discussions'
      ]
    },
    {
      id: 'productivity',
      title: 'Productivity Management',
      description: 'Maintaining high productivity levels in remote work environments.',
      solutions: [
        'Set clear performance expectations',
        'Use time tracking tools',
        'Establish daily routines',
        'Create dedicated workspaces'
      ]
    },
    {
      id: 'culture',
      title: 'Team Culture & Engagement',
      description: 'Building and maintaining company culture in virtual settings.',
      solutions: [
        'Organize virtual team building activities',
        'Recognize employee achievements',
        'Foster informal interactions',
        'Maintain regular social connections'
      ]
    }
  ]

  return (
    <Layout className="remote-work-page">
      <SEO 
        title="Remote Work Guide & Best Practices | Performance Reviews" 
        description="Comprehensive guide to implementing and managing successful remote work programs. Learn best practices for communication, productivity, and team engagement in remote settings."
        keywords={[
          "remote work guide",
          "remote work best practices",
          "virtual team management",
          "remote communication",
          "remote productivity",
          "remote work tools",
          "remote work policy",
          "remote team building",
          "virtual collaboration",
          "remote work culture"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        bg: 'primaryMuted',
        pt: [3, 4],
        pb: [3, 4]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <h1 sx={{
            fontSize: ['2rem', '2.5rem'],
            fontWeight: 700,
            textAlign: 'center',
            mb: 2,
            color: 'primary'
          }}>
            Remote Work Guide
          </h1>
          
          <p sx={{
            fontSize: '1.1rem',
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 3,
            lineHeight: 1.5,
            color: 'text'
          }}>
            Build and manage successful remote teams with our comprehensive guide to remote work. 
            Learn essential strategies for communication, productivity, and team engagement in virtual environments.
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div sx={{ maxWidth: '1200px', mx: 'auto', px: 3, py: [3, 4] }}>
        {/* Key Areas Section */}
        <section sx={{ mb: [4, 5] }}>
          <h2 sx={{ 
            fontSize: ['1.75rem', '2rem'],
            fontWeight: 600,
            mb: 3,
            color: 'primary'
          }}>
            Key Areas of Remote Work
          </h2>
          
          <div sx={{ 
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr'],
            gap: 3,
            mb: 4
          }}>
            {remoteWorkAreas.map(area => (
              <div key={area.id} sx={{
                bg: 'white',
                p: 3,
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: '0 4px 15px rgba(0,0,0,0.15)'
                }
              }}>
                <h3 sx={{ fontSize: '1.4rem', mb: 3, color: 'primary' }}>{area.title}</h3>
                <ul sx={{ 
                  listStyle: 'none',
                  p: 0,
                  m: 0
                }}>
                  {area.items.map(item => (
                    <li key={item.text} sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      mb: 2
                    }}>
                      <RiCheckboxCircleLine sx={{ color: 'primary', mr: 2, flexShrink: 0 }} />
                      <a 
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: 'primary',
                          textDecoration: 'none',
                          '&:hover': {
                            textDecoration: 'underline'
                          }
                        }}
                      >
                        {item.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Implementation Strategy Section */}
          <div sx={{
            bg: 'white',
            p: 4,
            borderRadius: '8px',
            mb: 5,
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
          }}>
            <h3 sx={{ 
              fontSize: ['1.3rem', '1.4rem'],
              mb: 4,
              color: 'primary',
              textAlign: 'center'
            }}>
              Implementation Strategy
            </h3>
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, '1fr 1fr'],
              gap: 4
            }}>
              <div sx={{
                bg: 'primaryMuted',
                p: 4,
                borderRadius: '8px',
                position: 'relative'
              }}>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 3,
                  gap: 2
                }}>
                  <RiToolsLine sx={{ fontSize: '1.8rem', color: 'primary' }} />
                  <h4 sx={{ 
                    fontSize: '1.2rem', 
                    color: 'primary',
                    m: 0
                  }}>Initial Setup</h4>
                </div>
                <ol sx={{ 
                  pl: 4,
                  mb: 0,
                  '& li': {
                    mb: 3,
                    '&:last-child': {
                      mb: 0
                    }
                  }
                }}>
                  <li sx={{ 
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2
                  }}>
                    <RiGlobalLine sx={{ color: 'primary', mt: '3px', flexShrink: 0 }} />
                    <span>Assess technology requirements and infrastructure needs</span>
                  </li>
                  <li sx={{ 
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2
                  }}>
                    <RiFileTextLine sx={{ color: 'primary', mt: '3px', flexShrink: 0 }} />
                    <span>Develop comprehensive remote work policies</span>
                  </li>
                  <li sx={{ 
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2
                  }}>
                    <RiComputerLine sx={{ color: 'primary', mt: '3px', flexShrink: 0 }} />
                    <span>Set up secure communication and collaboration tools</span>
                  </li>
                  <li sx={{ 
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2
                  }}>
                    <RiTeamLine sx={{ color: 'primary', mt: '3px', flexShrink: 0 }} />
                    <span>Create comprehensive training and onboarding materials</span>
                  </li>
                </ol>
              </div>
              
              <div sx={{
                bg: 'primaryMuted',
                p: 4,
                borderRadius: '8px',
                position: 'relative'
              }}>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 3,
                  gap: 2
                }}>
                  <RiTimeLine sx={{ fontSize: '1.8rem', color: 'primary' }} />
                  <h4 sx={{ 
                    fontSize: '1.2rem', 
                    color: 'primary',
                    m: 0
                  }}>Ongoing Management</h4>
                </div>
                <ol sx={{ 
                  pl: 4,
                  mb: 0,
                  '& li': {
                    mb: 3,
                    '&:last-child': {
                      mb: 0
                    }
                  }
                }}>
                  <li sx={{ 
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2
                  }}>
                    <RiTeamLine sx={{ color: 'primary', mt: '3px', flexShrink: 0 }} />
                    <span>Schedule and conduct regular team check-ins and updates</span>
                  </li>
                  <li sx={{ 
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2
                  }}>
                    <RiCheckboxCircleLine sx={{ color: 'primary', mt: '3px', flexShrink: 0 }} />
                    <span>Monitor and evaluate remote team performance</span>
                  </li>
                  <li sx={{ 
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2
                  }}>
                    <RiToolsLine sx={{ color: 'primary', mt: '3px', flexShrink: 0 }} />
                    <span>Regularly assess and optimize collaboration tools</span>
                  </li>
                  <li sx={{ 
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2
                  }}>
                    <RiShieldCheckLine sx={{ color: 'primary', mt: '3px', flexShrink: 0 }} />
                    <span>Review and update remote work policies as needed</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>

          {/* Common Challenges Section */}
          <h3 sx={{ 
            fontSize: ['1.3rem', '1.4rem'],
            mb: 4,
            color: 'primary',
            textAlign: 'center'
          }}>
            Common Remote Work Challenges & Solutions
          </h3>
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', null, '1fr 1fr 1fr'],
            gap: 4,
            mb: 4
          }}>
            {commonChallenges.map(challenge => (
              <div key={challenge.id} sx={{
                bg: 'white',
                p: 4,
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                display: 'flex',
                flexDirection: 'column'
              }}>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mb: 3
                }}>
                  {challenge.id === 'communication' && <RiComputerLine sx={{ fontSize: '1.8rem', color: 'primary', flexShrink: 0 }} />}
                  {challenge.id === 'productivity' && <RiTimeLine sx={{ fontSize: '1.8rem', color: 'primary', flexShrink: 0 }} />}
                  {challenge.id === 'culture' && <RiTeamLine sx={{ fontSize: '1.8rem', color: 'primary', flexShrink: 0 }} />}
                  <h4 sx={{ 
                    fontSize: '1.2rem',
                    color: 'primary',
                    m: 0
                  }}>
                    {challenge.title}
                  </h4>
                </div>
                <p sx={{ 
                  mb: 3,
                  flex: '1 0 auto',
                  fontSize: '0.95rem',
                  lineHeight: 1.5
                }}>{challenge.description}</p>
                <div sx={{
                  bg: 'primaryMuted',
                  p: 3,
                  borderRadius: '6px'
                }}>
                  <h5 sx={{ 
                    fontSize: '1rem', 
                    mb: 2, 
                    color: 'primary',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiCheckboxCircleLine />
                    Solutions
                  </h5>
                  <ul sx={{ 
                    pl: 0,
                    m: 0,
                    listStyle: 'none',
                    '& li': {
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 2,
                      mb: 2,
                      fontSize: '0.95rem',
                      '&:last-child': {
                        mb: 0
                      }
                    }
                  }}>
                    {challenge.solutions.map(solution => (
                      <li key={solution}>
                        <RiArrowRightSLine sx={{ color: 'primary', mt: '3px', flexShrink: 0 }} />
                        <span>{solution}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>

          {/* Resources Section */}
          <h3 sx={{ 
            fontSize: ['1.3rem', '1.4rem'],
            mb: 3,
            color: 'primary',
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-10px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '60px',
              height: '3px',
              bg: 'primary',
              borderRadius: '2px'
            }
          }}>
            Essential Remote Work Resources
          </h3>
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr', '1fr 1fr 1fr'],
            gap: 3,
            mb: 4
          }}>
            <Link to="/guides" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              textDecoration: 'none',
              color: 'text',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'translateY(-4px)'
              }
            }}>
              <RiFileTextLine sx={{ fontSize: '2rem', color: 'primary', mb: 2 }} />
              <h4 sx={{ fontSize: '1.2rem', mb: 2, color: 'primary' }}>HR Guides Library</h4>
              <p sx={{ mb: 0 }}>Access our complete collection of HR guides including remote onboarding, virtual meetings, and team management</p>
            </Link>
            
            <Link to="/tools" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              textDecoration: 'none',
              color: 'text',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'translateY(-4px)'
              }
            }}>
              <RiToolsLine sx={{ fontSize: '2rem', color: 'primary', mb: 2 }} />
              <h4 sx={{ fontSize: '1.2rem', mb: 2, color: 'primary' }}>HR Tools & Calculators</h4>
              <p sx={{ mb: 0 }}>Interactive tools for remote team management, performance tracking, and productivity analysis</p>
            </Link>
            
            <Link to="/policies" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              textDecoration: 'none',
              color: 'text',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'translateY(-4px)'
              }
            }}>
              <RiFileTextLine sx={{ fontSize: '2rem', color: 'primary', mb: 2 }} />
              <h4 sx={{ fontSize: '1.2rem', mb: 2, color: 'primary' }}>Policy Templates</h4>
              <p sx={{ mb: 0 }}>Customizable remote work policy templates and documentation guidelines</p>
            </Link>
          </div>

          {/* Additional Resources Grid */}
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
            gap: 3,
            mb: 4
          }}>
            <Link to="/guides/employee-onboarding" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              textDecoration: 'none',
              color: 'text',
              transition: 'transform 0.2s ease',
              display: 'flex',
              alignItems: 'flex-start',
              gap: 3,
              '&:hover': {
                transform: 'translateY(-4px)'
              }
            }}>
              <RiTeamLine sx={{ fontSize: '2rem', color: 'primary', flexShrink: 0 }} />
              <div>
                <h4 sx={{ fontSize: '1.2rem', mb: 2, color: 'primary' }}>Employee Onboarding Guide</h4>
                <p sx={{ mb: 0 }}>Step-by-step guide for onboarding new employees in a remote environment, including checklists and best practices</p>
              </div>
            </Link>
            
            <Link to="/contact" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              textDecoration: 'none',
              color: 'text',
              transition: 'transform 0.2s ease',
              display: 'flex',
              alignItems: 'flex-start',
              gap: 3,
              '&:hover': {
                transform: 'translateY(-4px)'
              }
            }}>
              <RiComputerLine sx={{ fontSize: '2rem', color: 'primary', flexShrink: 0 }} />
              <div>
                <h4 sx={{ fontSize: '1.2rem', mb: 2, color: 'primary' }}>Get Virtual Meeting Resources</h4>
                <p sx={{ mb: 0 }}>Contact us for our comprehensive guide to running effective virtual meetings and team collaboration tools</p>
              </div>
            </Link>
          </div>

          {/* CTA Section */}
          <div sx={{
            bg: 'primaryMuted',
            p: 4,
            borderRadius: '8px',
            textAlign: 'center',
            mt: 4
          }}>
            <h3 sx={{ 
              fontSize: ['1.2rem', '1.3rem'],
              mb: 3,
              color: 'primary'
            }}>
              Need Help Setting Up Your Remote Work Program?
            </h3>
            <p sx={{ mb: 4, fontSize: '1.1rem', maxWidth: '800px', mx: 'auto' }}>
              Our team of HR experts can help you develop and implement effective remote work policies, 
              set up virtual collaboration tools, and create engaging remote team experiences.
            </p>
            <Link to="/contact" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'primary',
              color: 'white',
              py: 2,
              px: 4,
              borderRadius: '6px',
              fontSize: '1.1rem',
              fontWeight: 600,
              textDecoration: 'none',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                color: 'white'
              }
            }}>
              Contact Our Remote Work Consultants <RiArrowRightSLine />
            </Link>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default RemoteWorkPage 